import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import settings from "../styles/global/settings";

interface HabWhiteBoxProps {
  removePadding?: boolean,
  smallPadding?: boolean,
}

const HabWhiteBox = styled.div.attrs(() => ({
  className: "HabWhiteBox",
}))`
  width: 100%;
  background-color: ${settings.color.white};
  text-align: center;
  justify-content: center;
  border-radius: 60px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 34px;
  padding-right: 34px;
  ${(props: HabWhiteBoxProps) =>
    props.removePadding === true &&
    css`
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
    `};
  ${(props: HabWhiteBoxProps) =>
    props.smallPadding === true &&
    css`
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
    `};
`;

const WhiteBox = ({
  children,
  noPadding,
  smallPadding,
}: PropTypes.InferProps<typeof WhiteBox.propTypes>): JSX.Element => {
  const removePadding = noPadding === true;
  return (
    <HabWhiteBox smallPadding={smallPadding} removePadding={removePadding}>
      {children}
    </HabWhiteBox>
  );
};

WhiteBox.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  smallPadding: PropTypes.bool,
};

export default WhiteBox;
