import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import settings from "../../styles/global/settings";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import {
  HabLeadCaptureTitle,
  HabLeadCaptureSubtitle,
} from "../../styles/global/SharedStyles";
import { Helmet } from "react-helmet";
import BottomFloat from "./BottomFloat.component";
import Button from "../Button.component";
import BottomNavButtons from "../BottomNavButtons";
import GlobalStyle from "../../styles/global/GlobalStyle";

interface ContainerProps {
  backgroundColor?: string;
}

const HabLayoutContainer = styled.div.attrs(() => ({
  className: "HabLayoutContainer",
}))`
  min-height: 100vh;

  ${(props: ContainerProps) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
        }
      `};
`;

const YellowHeader = styled.div.attrs(() => ({
  className: "YellowHeader",
}))`
  height: 226px;
  background-color: ${settings.color.primary_2};
`;

const SmallHeader = styled.div.attrs(() => ({
  className: "SmallHeader",
}))`
  height: 97px;
  background-color: ${settings.color.primary_2};
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: ${settings.color.primary_1};
`;

const HabTitleContainer = styled.div.attrs(() => ({
  className: "HabTitleContainer",
}))`
  max-width: 347px;
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-grow: 1;
  padding-top: 0px;
  text-align: center;

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (min-width: ${settings.bp.small.view}) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    max-width: 640px;
  }
`;

interface ContentContainerProps {
  hasHeader?: boolean;
  yellowHeader?: boolean;
}
const HabContentContainer = styled.div.attrs(() => ({
  className: "HabContentContainer",
}))`
  align-content: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  ${(props: ContentContainerProps) =>
    props.hasHeader &&
    css`
      min-height: calc(100vh - 72px)
        }
      `};
  ${(props: ContentContainerProps) =>
    props.yellowHeader &&
    css`
      margin-top: -226px;
        }
      `};

  h3 {
    margin-top: 0px;
  }
  padding-left: 0px;
  padding-right: 0px;
  h3 {
    margin-top: 0px;
  }
`;

const Layout = ({
  children,
  showProgress,
  group,
  index,
  showHeader,
  title,
  subtitle,
  example,
  backgroundColor,
  segmentTitle,
  buttons,
  yellowHeader,
  smallHeader,
  headerTitle,
}: PropTypes.InferProps<typeof Layout.propTypes>): JSX.Element => {
  useEffect(() => {
    if (window && window.analytics) {
      window.analytics.page(segmentTitle || title);
    }
  }, [segmentTitle, title]);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&family=DM+Serif+Display&display=swap"
          rel="stylesheet"
        />
        <meta charSet="utf-8" />
        <title>Habitual programme selector</title>
        <link rel="canonical" href="https://apply.tryhabitual.com/" />
      </Helmet>
      <GlobalStyle />
      <HabLayoutContainer backgroundColor={backgroundColor}>
        {yellowHeader && <YellowHeader />}
        {smallHeader && <SmallHeader>
          <div style={{lineHeight: '130px'}}>
          {headerTitle}
          </div>
          </SmallHeader>}
        {showHeader && <Header />}
        {showProgress && (
          <ProgressBar
            currentGroup={group}
            currentIndex={index}
            group1Count={13}
            group2Count={5}
            group3Count={5}
          />
        )}
        {title && (
          <HabTitleContainer>
            <HabLeadCaptureTitle>
              <h3>{title}</h3>
            </HabLeadCaptureTitle>
          </HabTitleContainer>
        )}
        {subtitle && (
          <HabTitleContainer>
            <HabLeadCaptureSubtitle>
              {example && <h3>{example}</h3>}
              <h3>{subtitle}</h3>
            </HabLeadCaptureSubtitle>
          </HabTitleContainer>
        )}
        <HabContentContainer hasHeader={showHeader} yellowHeader={yellowHeader}>
          {children}
        </HabContentContainer>
        {buttons && (
          <BottomFloat margin>
            {buttons.map((b, index) => {
              if (b.showBack) {
                return (
                  <BottomNavButtons
                    onBackClick={b.onBackClick}
                    key={`ctabutton-${index}`}
                    secondary={b.secondary}
                    title={b.title}
                    onClick={b.onClick}
                    disabled={b.disabled}
                    tag={b.tag}
                  />
                );
              } else {
                return (
                  <Button
                    key={`ctabutton-${index}`}
                    secondary={b.secondary}
                    title={b.title}
                    onClick={b.onClick}
                    disabled={b.disabled}
                    tag={b.tag}
                  />
                );
              }
            })}
          </BottomFloat>
        )}
      </HabLayoutContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showProgress: PropTypes.bool,
  group: PropTypes.number,
  index: PropTypes.number,
  showNavigation: PropTypes.bool,
  navigationTitle: PropTypes.string,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  example: PropTypes.string,
  backgroundColor: PropTypes.string,
  segmentTitle: PropTypes.string,
  buttons: PropTypes.array,
  yellowHeader: PropTypes.bool,
  smallHeader: PropTypes.bool,
  headerTitle: PropTypes.string,
};

export default Layout;
