import { AlcoholOptions } from "../screens/AlcoholQuestion";
import { BeansAndPulsesOptions } from "../screens/BeansAndPulsesQuestion";
import { CarbsOptions } from "../screens/CarbsQuestion";
import { CarbsTypeOptions } from "../screens/CarbsTypeQuestion";
import { ConcernsOptions } from "../screens/ConcernsQuestion";
import { ConvenienceOptions } from "../screens/ConvenienceFoodQuestion";
import { CookingOptions } from "../screens/CookingQuestion";
import { Dietary } from "../screens/DietaryRequirementsQuestion";
import { DishOptions } from "../screens/DishQuestion";
import { EatingOutOptions } from "../screens/EatingOutQuestion";
import { FatsAndDairyOptions } from "../screens/FatAndDairyQuestion";
import { FishMeatEggsOptions } from "../screens/FishMeatEggsQuestion";
import { FruitAndVegOptions } from "../screens/FruitAndVegQuestion";
import { Goal } from "../screens/HealthGoalQuestion";
import { LifestyleOptions } from "../screens/LifestyleQuestion";
import { MealPlanningOptions } from "../screens/MealPlanningQuestion";
import { MealsOptions } from "../screens/MealsQuestion";
import { MotivatorOptions } from "../screens/MotivatorsQuestion";
import { Speed } from "../screens/SpeedQuestion";
import { SweetOrSavoury } from "../screens/SweetOrSavouryQuestion";
import { ToolsOptions } from "../screens/ToolsQuestion";
import { TriedBeforeOptions } from "../screens/TriedBeforeQuestion";
import { UnsuccessfulOptions } from "../screens/UnsuccessfulQuestion";

export enum ProgrammeTypes {
  TDR = "TDR",
  TDR_PLUS = "Replacement 800",
  KETO = "Keto",
  WHOLEFOODS = "Wholefoods",
  LOW_CARB = "Low carb",
}

export type Scores = {
  score: number;
  type: ProgrammeTypes;
  match: number;
};

export type AddLead = {
  hubspotData: HubspotData;
  cioData: CioData;
};

type CioData = {
  email: string;
  created_at: number;
  "Program Preference": ProgrammeTypes;
  Goal: Goal;
  "Newsletter OptIn": boolean;
  "GDPR accepted": boolean;
};

type HubspotData = {
  properties: {
    email: string;
    lc_goal_diabetes_reversal?: boolean;
    lc_goal_diabetes_prevention?: boolean;
    lc_goal_weight_loss?: boolean;
    lc_gdpr: boolean;
    lc_newsletter_optin: boolean;
    lc_programme_selected: ProgrammeTypes;
  };
};

export type Answers = {
  email: string;
  goal: Goal | null;
  alcohol: AlcoholOptions | null;
  speed: Speed | null;
  dietary: Dietary[];
  sweetOrSavoury: SweetOrSavoury | null;
  fatsAndDairy: FatsAndDairyOptions | null;
  fruitAndVeg: FruitAndVegOptions | null;
  fishMeatEggs: FishMeatEggsOptions | null;
  beansAndPulses: BeansAndPulsesOptions | null;
  convenienceFood: ConvenienceOptions | null;
  carbs: CarbsOptions | null;
  carbType: CarbsTypeOptions | null;
  dish: DishOptions | null;
  lifestyle: LifestyleOptions | null;
  eatingOut: EatingOutOptions | null;
  mealPrep: MealPlanningOptions | null;
  cooking: CookingOptions | null;
  meals: MealsOptions | null;
  triedBefore: TriedBeforeOptions[];
  unsuccessful: UnsuccessfulOptions | null;
  concerns: ConcernsOptions | null;
  motivator: MotivatorOptions | null;
  tools: ToolsOptions | null;
  scores: Scores[];
  programmeDetail: ProgrammeDetails | null;
  selectedProgramme: ProgrammeTypes | null;
  gdpr: boolean | null;
  newsletter: boolean | null;
  hubspotId: number | null;
};

export type ProgrammeDetails = {
  content: string[];
  url: string;
  title: string;
  index: number;
};

export const defaultAnswers: Answers = {
  email: "",
  goal: null,
  alcohol: null,
  speed: null,
  dietary: [],
  sweetOrSavoury: null,
  fatsAndDairy: null,
  fruitAndVeg: null,
  fishMeatEggs: null,
  beansAndPulses: null,
  convenienceFood: null,
  carbs: null,
  carbType: null,
  dish: null,
  lifestyle: null,
  eatingOut: null,
  mealPrep: null,
  cooking: null,
  meals: null,
  triedBefore: [],
  unsuccessful: null,
  concerns: null,
  motivator: null,
  tools: null,
  scores: [],
  programmeDetail: {
    content: null,
    url: null,
    title: null,
    index: null,
  },
  selectedProgramme: null,
  gdpr: false,
  newsletter: false,
  hubspotId: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mockAnswers: any = {
  "email": "",
  "goal": "Lose Weight",
  "alcohol": "Few times per week",
  "speed": "Quicker the better",
  "dietary": [
      "Flexitarian"
  ],
  "sweetOrSavoury": "I love both!",
  "fatsAndDairy": "Often",
  "fruitAndVeg": "Often",
  "fishMeatEggs": "Often",
  "beansAndPulses": "Often",
  "convenienceFood": "Often",
  "carbs": "I like them",
  "carbType": "Refined\n(white bread, white rice etc)",
  "dish": "Surf and turf",
  "lifestyle": "Sociable",
  "eatingOut": "Few times per month",
  "mealPrep": "Easy",
  "cooking": "I like it",
  "meals": "More than 3",
  "triedBefore": [
      "Cutting out sugar",
      "Counting calories or points",
      "Sleep-tracking app",
      "Exercise app"
  ],
  "unsuccessful": "Too expensive",
  "concerns": "Cost",
  "motivator": "Having teammates",
  "tools": "Blog style articles",
  "scores": [
      {
          "type": "TDR",
          "score": 1,
          "match": 65
      },
      {
          "type": "Replacement 800",
          "score": 2,
          "match": 68
      },
      {
          "type": "Wholefoods",
          "score": 3,
          "match": 73
      },
      {
          "type": "Keto",
          "score": 6,
          "match": 82
      },
      {
          "type": "Low carb",
          "score": 6,
          "match": 81
      }
  ],
  "programmeDetail": {
      "content": null,
      "url": null,
      "title": null,
      "index": null
  },
  "selectedProgramme": "TDR",
  "gdpr": true,
  "newsletter": true,
  "hubspotId": null
};
