const settings = {
  bp: {
    extraSmall: {
      view: "375px",
      width: "calc(100% - 40px)",
    },
    small: {
      view: "768px",
      width: "738px",
    },
    medium: {
      view: "1024px",
      width: "900px",
    },
    large: {
      view: "1200px",
      width: "calc(100% - 100px)",
    },
  },
  maxBlockWidth: "1170px",
  bgOverlay: "rgba(8, 8, 8, 0.64)",
  color: {
    default: "#000",
    primary_1: "#2c4a43", // Dark Green
    habitual_dark_40: "#2c4a4366", // Dark Green 40% opacity
    primary_2: "#ffe768", // Yellow
    primary_3: "#5da7eb", // Blue
    primary_4: "#6ce4bf", // Green
    ground: "#f5f8f2", // Ground
    path: "#ebeee7", // Path
    hand_green: "#7ab9a6", // Handy Green
    brain_pink: "#d1a593", // Brainy Pink
    heart_red: "#ee8868", // Hearty red
    error: "#f0663b", // UI color for Error
    success: "#6ce4bf", // UI color for Success
    grey: "#d8dcd4", // UI color for Grey
    white: "#ffffff", // UI color for White
    light_blue: "#acd2f6",
    shadow: "#2c4a431F", // Dark Green
    light_green: "#b2f1dd26",
    heart_red_semi: "#ee886826", // Hearty red
  },
  font: {
    default: "'DM Sans', sans-serif",
    headings: "'DM Serif Display', 'DM Sans', sans-serif",
  },
}

export default settings
