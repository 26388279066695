export const SCREENS = {
  WelcomeScreen: "Welcome Screen",
  GoalSelectScreen: "Goal Select",
  GoalResultScreen: "Goal Results",
  GoalDiabetesScreen: "Diabetes",
  GoalPrediabetesScreen: "Pre Diabetes",
  GoalWeightLossScreen: "Lose Weight",
  SpeedScreen: "Result Speed",
  DietaryScreen: "Dietary Requirements",
  NoDairyScreen: "Vegan & Lactose Intolerance Information",
  SweetOrSavouryScreen: "Sweet or Savoury",
  FatsScreen: "Fat & Dairy",
  IsabelScreen: "Isabel Testimonial",
  FruitAndVegScreen: "Fruit & Veg",
  NoFruitScreen: "No Fruit & Veg Information",
  FishMeatEggsScreen: "Fish, Meat & Eggs",
  BeansAndPulsesScreen: "Beans & Pulses",
  ConvenienceScreen: "Convenience Food",
  NearlyThereScreen: "Nearly There Information",
  AlcoholQuestionScreen: "Alcohol",
  AlcoholInformationScreen: "Alcohol Information",
  CarbsQuestionScreen: "Carbohydrates",
  CarbsTypeQuestionScreen: "Carbohydrate Preference",
  DishQuestionScreen: "Dish Choice",
  FoodPreferencesCompleteScreen: "Food Preferences Completed",
  LifestyleQuestionScreen: "Lifestyle",
  EatingOutQuestionScreen: "Eating Out",
  MealPlanningQuestionScreen: "Meal Planning",
  SheilaScreen: "Sheila Testimonial",
  CookingQuestionScreen: "Cooking",
  MealsQuestionScreen: "Meals per Day",
  LifestyleCompletedScreen: "Lifestyle Completed",
  TriedBeforeQuestionScreen: "Weight Loss Past Attempts",
  UnsuccessfulQuestionScreen: "Why unsuccessful",
  ConcernsQuestionScreen: "Biggest Concern",
  MotivatorsQuestionScreen: "Biggest Motivation",
  BulaScreen: "Bula Testimonial",
  ToolsQuestionScreen: "Learning Tools",
  QuizCompleteScreen: "Quiz Complete",
  RecommendationScreen: "Programme Recommendation",
  RecommendationDetailScreen: "Programme Detail",
  EmailScreen: "Email Capture"
}
