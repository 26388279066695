import React from "react";
import styled, { css } from "styled-components";
import settings from "../styles/global/settings";
import WhiteBox from "./WhiteBox";
import { Ps } from "../styles/global/SharedStyles";
import PropTypes from "prop-types";

interface HabSelectListProps {
  hasTags?: boolean;
}

const HabSelectListItem = styled.div`
  display: flex;
  flex-direction: "row";
  margin-bottom: 17px;
  width: 100%;
  min-width: 100%;
  ${(props: HabSelectListProps) =>
    props.hasTags === true &&
    css`
      margin-bottom: 30px;
    `};
`;

const HabSelectListItemIcon = styled.div.attrs(() => ({
  className: "HabSelectListItemIcon",
}))`
  margin-right: 20px;
  margin-left: 10px;
  width: 38px;
  img {
    height: 38px;
  }
`;

const HabSelectListItemTag = styled.div`
  position: relative;
  right: 10px;
  margin-left: auto;
  top: -15px;
  height: 30px;
  border-radius: 10px;
  width: 80px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;

interface HabSelectListItemContentProps {
  hasIcon?: boolean;
}
const HabSelectListItemContent = styled.div.attrs(() => ({
  className: "HabSelectListItemContent",
}))`
  font-size: 16px;
  line-height: 20px;
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  margin: auto;
  color: ${settings.color.primary_1};
  ${(props: HabSelectListItemContentProps) =>
    props.hasIcon === true &&
    css`
      line-height: 38px;
    `};
`;

interface HabSelectListContainerProps {
  isSelected?: boolean;
  smallPadding?: boolean;
  hasTag?: boolean;
}
const HabSelectListContainer = styled.div.attrs(() => ({
  className: "HabSelectListContainer",
}))`
  flex-direction: row;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  border: 3px solid transparent;
  box-sizing: border-box;
  border-radius: 60px;
  box-shadow: 10px 10px 30px 0px ${settings.color.shadow};
  &:hover {
    border: 3px solid ${settings.color.primary_2};
  }
  ${(props: HabSelectListContainerProps) =>
    props.isSelected === true &&
    css`
      border: 3px solid ${settings.color.primary_4};
      &:hover {
        border: 3px solid ${settings.color.primary_4};
      }
    `};
  ${(props: HabSelectListContainerProps) =>
    props.smallPadding === true &&
    css`
      padding-top: 10px;
      padding-bottom: 10px;
    `};
  ${(props: HabSelectListContainerProps) =>
    props.hasTag === true &&
    css`
      margin-top: -25px;
      margin-bottom: 5px;
    `};
`;

const HabSelectList = styled.div.attrs(() => ({
  className: "HabSelectList",
}))`
  text-align: left;
  margin: auto;
  margin-top: 42px;
  margin-bottom: 100px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  max-width: 375px;
`;

const SelectList = ({
  items,
  selectedItems,
  onSelect,
  smallPadding,
}: PropTypes.InferProps<typeof SelectList.propTypes>): JSX.Element => {
  return (
    <>
      <HabSelectList>
        {items.map((item) => {
          const isSelected = selectedItems.includes(item.id);
          return (
            <HabSelectListItem
              key={`li-${item.id}`}
              onClick={() => onSelect(item.id)}
              hasTags={item.tag ? true : false}
            >
              <WhiteBox noPadding>
                {item.tag && (
                  <HabSelectListItemTag
                    color={item.tagColor || settings.color.primary_3}
                  >
                    <Ps style={{ opacity: 1, lineHeight: "150%" }}>
                      {item.tag}
                    </Ps>
                  </HabSelectListItemTag>
                )}
                <HabSelectListContainer
                  smallPadding={smallPadding}
                  isSelected={isSelected}
                  hasTag={item.tag ? true : false}
                >
                  {item.icon && item.icon.src && (
                    <HabSelectListItemIcon>
                      {item.icon.src}
                    </HabSelectListItemIcon>
                  )}
                  <HabSelectListItemContent
                    hasIcon={item.icon && item.icon.src ? true : false}
                  >
                    {item.text}
                    {item.subText && <br />}
                    {item.subText}
                  </HabSelectListItemContent>
                </HabSelectListContainer>
              </WhiteBox>
            </HabSelectListItem>
          );
        })}
      </HabSelectList>
    </>
  );
};

SelectList.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  onSelect: PropTypes.func,
  smallPadding: PropTypes.bool,
};

export default SelectList;
