import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { isIOS } from "react-device-detect";

interface HabBottomFloatContainerProps {
  margin?: boolean;
  removeTopPadding?: boolean;
  iOS?: boolean;
}

const HabBottomFloatContainer = styled.div.attrs(() => ({
  className: "HabBottomFloatContainer",
}))`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  padding-top: 40px;
  z-index: 5;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: 100px;
  background: linear-gradient(
    0deg,
    rgba(245, 248, 242, 1) 60%,
    rgba(245, 248, 242, 0) 100%
  );
  ${(props: HabBottomFloatContainerProps) =>
    props.margin &&
    css`
      margin-bottom: 0px;
    `};
  ${(props: HabBottomFloatContainerProps) =>
    props.iOS &&
    css`
      padding-bottom: 20px;
    `};
  ${(props: HabBottomFloatContainerProps) =>
    props.removeTopPadding &&
    css`
      padding-top: 0px;
    `};
`;

const BottomFloat = ({
  children,
  margin,
  removeTopPadding,
}: PropTypes.InferProps<typeof BottomFloat.propTypes>): JSX.Element => {
  return (
    <HabBottomFloatContainer
      iOS={isIOS}
      margin={margin}
      removeTopPadding={removeTopPadding}
    >
      {children}
    </HabBottomFloatContainer>
  );
};

BottomFloat.propTypes = {
  children: PropTypes.node.isRequired,
  margin: PropTypes.bool,
  removeTopPadding: PropTypes.bool,
};

export default BottomFloat;
