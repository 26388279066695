import axios from "axios";
import { Goal } from "../screens/HealthGoalQuestion";
import { AddLead, Answers } from "../types/constants";
import { identifyUser, trackSubmissionEvent } from "./segment-helper";
const axios_instance = axios.create({
  baseURL: "https://us-central1-programme-selector-quiz.cloudfunctions.net",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const scrollToTop = (): void => {
  if (window && window.scrollTo) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const openInNewTab = (url: string): void => {
  window.open(url, "_blank");
};

export const addLead = async (state: Answers): Promise<boolean> => {
  const body = formatAddLeadBody(state);
  const response = await axios_instance
    .post(`/addLead`, body)
    .then((response) => {
      if (response && response.status === 200) {
        trackSubmissionEvent(body)
        identifyUser(state.email)
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false;
    });

  return response;
};

const formatAddLeadBody = (state: Answers): AddLead => {
  return {
    hubspotData: {
      properties: {
        email: state.email,
        lc_goal_diabetes_reversal: state.goal === Goal.Reverse,
        lc_goal_diabetes_prevention: state.goal === Goal.Prevent,
        lc_goal_weight_loss: state.goal === Goal.Weight,
        lc_gdpr: state.gdpr || false,
        lc_newsletter_optin: state.newsletter || false,
        lc_programme_selected: state.selectedProgramme,
      },
    },
    cioData: {
      email: state.email,
      created_at: new Date().getTime() / 1000,
      "Program Preference": state.selectedProgramme,
      Goal: state.goal,
      "Newsletter OptIn": state.newsletter,
      "GDPR accepted": state.gdpr,
    },
  };
};
