export const trackCustomEvent = function (name: string, object: unknown): void {
  if (typeof fbq !== "undefined" && fbq) {
    fbq("trackCustom", name, object)
  }
}

export const trackLead = function (): void  {
  if (typeof fbq !== "undefined" && fbq) {
    fbq("track", "Lead")
  }
}

export const trackLCQStart = function (): void  {
  if (typeof fbq !== "undefined" && fbq) {
    fbq("track", "StartTrial")
  }
}

export const trackLCQEnd = function (): void  {
  if (typeof fbq !== "undefined" && fbq) {
    fbq("track", "SubmitApplication")
  }
}

export const trackViewContent = function (title: string): void  {
  if (typeof fbq !== "undefined" && fbq) {
    fbq("track", "ViewContent", { content_name: title })
  }
}

export const CUSTOM_EVENT_NAMES = {
  LcqStart: "LCQstart",
  Eligible: "Eligible",
  EligibleEmail: "EligibleEmail",
  LcqComplete: "LCQcomplete",
}
