import * as React from "react";
import settings from "../styles/global/settings";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import ScalesIcon from "../svg/icon-scales.svg";
import DiabetesIcon from "../svg/icon-diabetes.svg";
import HeartIcon from "../svg/icon-heart.svg";
import SelectList from "../components/SelectList";
import { SCREENS } from "../helpers/screen-names-helper";

export enum Goal {
  Reverse = "Reverse Diabetes",
  Prevent = "Prevent Diabetes",
  Weight = "Lose Weight",
}

const HealthGoalQuestion = ({
  onClick,
  onBackClick,
  goal,
  setGoal,
}: PropTypes.InferProps<typeof HealthGoalQuestion.propTypes>): JSX.Element => {
  const buttons = [
    {
      title: `Continue`,
      onClick: () => onClick(),
      showBack: true,
      onBackClick: () => onBackClick(),
      disabled: !goal
    },
  ];
  return (
    <Layout
      showProgress
      group={0}
      index={0}
      buttons={buttons}
      backgroundColor={settings.color.path}
      title={"What’s your health goal?"}
      subtitle={"Please select one"}
      segmentTitle={SCREENS.GoalSelectScreen}
    >
      <SelectList
        smallPadding
        selectedItems={[goal]}
        onSelect={(id) => {
          setGoal(id);
        }}
        items={[
          {
            id: Goal.Reverse,
            text: "Reverse diabetes",
            icon: { src: <DiabetesIcon />, alt: "needle icon" },
          },
          {
            id: Goal.Prevent,
            text: "Reverse prediabetes",
            icon: { src: <HeartIcon />, alt: "heart icon" },
          },
          {
            id: Goal.Weight,
            text: "Lose weight for life",
            icon: { src: <ScalesIcon />, alt: "scales icon" },
          },
        ]}
      />
    </Layout>
  );
};

HealthGoalQuestion.propTypes = {
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  goal: PropTypes.string,
  setGoal: PropTypes.func,
};

export default HealthGoalQuestion;
