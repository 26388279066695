import React from "react"
import styled from "styled-components"
import settings from "../../styles/global/settings"
import logo from "../../images/logo.png"

const HabHeader = styled.div.attrs(() => ({
  className: "HabHeader",
}))`
  // display: none;
  // @media (min-width: ${settings.bp.small.view}) {
    z-index: 10;
    height: 72px;
    width: 100%;
    display: flex;
    background-color: ${settings.color.white};
    text-align: center;
    justify-content: center;
    img {
      max-height: 35px;
      margin-right: auto;
      margin-left: 24px;
      margin-top: auto;
      margin-bottom: auto;
    }
  // }
`

const Header = (): JSX.Element => {
  return (
    <>
      <HabHeader>
        <img src={logo} alt="logo" />
      </HabHeader>
    </>
  )
}

export default Header
