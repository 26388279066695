import React from "react";
import styled, { css } from "styled-components";
import settings from "../styles/global/settings";
import PropTypes from "prop-types";
// import BackArrow from "../images/back-arrow.svg"

interface HabCtaButtonProps {
  secondary?: boolean;
  tertiary?: boolean;
}

const HabCtaButton = styled.div.attrs(() => ({
  className: "HabCtaButton",
}))`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 12px;
  margin-left: 24px;
  margin-right: 24px;

  button {
    max-width: 347px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: ${settings.color.primary_3};
    border-radius: 25px;
    padding: 10px;
    border: 2px solid transparent;
    margin-left: 8px;
    margin-right: 8px;
    font-family: "DM Sans";

    &:hover {
      background-color: #3c8dcf;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  ${(props: HabCtaButtonProps) =>
    props.secondary &&
    css`
      button {
        color: ${settings.color.primary_3};
        background-color: ${settings.color.ground};
        border: 2px solid ${settings.color.primary_3};
        &:hover {
          background-color: ${settings.color.path};
        }
      }
    `};
  ${(props: HabCtaButtonProps) =>
    props.tertiary &&
    css`
      button {
        color: ${settings.color.primary_3};
        background-color: transparent;
        border: 2px solid ${settings.color.primary_3};
        &:hover {
          background-color: transparent;
        }
      }
    `};
`;
const BottomNavButtons = ({
  title,
  onClick,
  onBackClick,
  disabled,
  secondary,
  tertiary,
  style,
}: PropTypes.InferProps<typeof BottomNavButtons.propTypes>): JSX.Element => {
  return (
    <div style={{maxWidth: '375px', margin: 'auto', width: '100%'}}>
    <HabCtaButton secondary={secondary} tertiary={tertiary} style={style}>
      <button
        onClick={() => onBackClick()}
        style={{
          minWidth: "48px",
          maxWidth: "48px",
          paddingLeft: "7px",
          paddingTop: "13px",
          maxHeight: "48px",
          minHeight: "48px",
          backgroundColor: settings.color.path,
          color: settings.color.primary_1,
        }}
      >
        <svg
          width="12"
          height="19"
          viewBox="0 0 12 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8656 1L2 9.5L10.8656 18"
            stroke="#2C4A43"
            strokeWidth="2"
          />
        </svg>
      </button>
      <button
        onClick={() => onClick()}
        style={{ width: "100%" }}
        disabled={disabled}
      >
        {title}
      </button>
    </HabCtaButton>
    </div>
  );
};

BottomNavButtons.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  onBackClick: PropTypes.func,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  style: PropTypes.any,
};

export default BottomNavButtons;
