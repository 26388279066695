import React from "react";
import styled, { css } from "styled-components";
import settings from "../styles/global/settings";
import { Ps } from "../styles/global/SharedStyles";
import PropTypes from "prop-types";

interface HabButtonProps {
  bold?: boolean;
  padded?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  teal?: boolean;
  onClick?: boolean;
  noMargin?: boolean;
  square?: boolean;
  alignEnd?: boolean;
  style?: boolean;
  mobileStyle?: boolean;
}

const HabCtaButton = styled.div.attrs(() => ({
  className: "HabCtaButton",
}))`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 12px;

  button {
    width: 80vw;
    max-width: 347px;
    font-size: 16px;
    color: #fff;
    background-color: ${settings.color.primary_3};
    border-radius: 25px;
    padding: 10px;
    border: 2px solid transparent;
    margin-left: auto;
    margin-right: auto;
    font-family: "DM Sans";

    &:hover {
      background-color: #3c8dcf;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  ${(props: HabButtonProps) =>
    props.secondary &&
    css`
      button {
        color: ${settings.color.primary_3};
        background-color: transparent;
        border: 2px solid ${settings.color.primary_3};
        &:hover {
          background-color: ${settings.color.ground};
        }
      }
    `};
  ${(props: HabButtonProps) =>
    props.tertiary &&
    css`
      button {
        color: ${settings.color.primary_3};
        background-color: transparent;
        border: 2px solid ${settings.color.primary_3};
        &:hover {
          background-color: transparent;
        }
      }
    `};
  ${(props: HabButtonProps) =>
    props.mobileStyle &&
    css`
      button {
        font-size: 13px;
        font-weight: 400;
      }
    `};
  ${(props: HabButtonProps) =>
    props.square &&
    css`
      margin-top: auto;
      margin-bottom: auto;
      @media (min-width: ${settings.bp.small.view}) {
        margin-right: 24px;
      }
      button {
        border-radius: 100px;
        max-width: 320px;
        margin-top: 4px;
        margin-bottom: 4px;
        font-weight: 700;
        width: 100%;
        @media (min-width: ${settings.bp.small.view}) {
          padding: 16px 24px;
          margin: 0;
        }
      }
    `};
  ${(props: HabButtonProps) =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `};
  ${(props: HabButtonProps) =>
    props.teal &&
    css`
      button {
        color: #fff;
        background-color: ${settings.color.primary_1};
        border: 2px solid transparent;
        &:hover {
          background-color: #233b36;
        }
      }
    `};
  ${(props: HabButtonProps) =>
    props.noMargin &&
    css`
      margin-top: 0px;
    `};
  ${(props: HabButtonProps) =>
    props.bold &&
    css`
      min-height: 55px;
      font-size: 18px;
      letter-spacing: 0em;
      margin-top: 24px;
      button {
        font-weight: 700;
      }
    `};
  ${(props: HabButtonProps) =>
    props.padded &&
    css`
      margin: 0;
      button {
        padding: 25px;
        border-radius: 200px;
      }
    `};
`;

interface HabButtonTagProps {
  color?: string;
}

const HabButtonTag = styled.div`
  position: relative;
  margin-left: auto;
  right: 20px;
  top: -105px;
  height: 30px;
  border-radius: 100px;
  width: 80px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${settings.color.primary_2};
  ${(props: HabButtonTagProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
  @media (min-width: ${settings.bp.small.view}) {
    top: -85px;
  }
`;

const Button = ({
  title,
  onClick,
  disabled,
  secondary,
  tertiary,
  bold,
  teal,
  noMargin,
  tag,
  padded,
  square,
  alignEnd,
  style,
  buttonStyle,
  mobileStyle,
}: PropTypes.InferProps<typeof Button.propTypes>): JSX.Element => {
  return (
    <HabCtaButton
      bold={bold}
      padded={padded}
      secondary={secondary}
      tertiary={tertiary}
      teal={teal}
      onClick={onClick}
      noMargin={noMargin}
      square={square}
      alignEnd={alignEnd}
      style={style}
      mobileStyle={mobileStyle}
    >
      <button style={buttonStyle} disabled={disabled}>{title}</button>
      {tag && (
        <HabButtonTag>
          <Ps style={{ opacity: 1, lineHeight: "150%" }}>{tag}</Ps>
        </HabButtonTag>
      )}
    </HabCtaButton>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  bold: PropTypes.bool,
  teal: PropTypes.bool,
  noMargin: PropTypes.bool,
  tag: PropTypes.string,
  padded: PropTypes.bool,
  square: PropTypes.bool,
  alignEnd: PropTypes.bool,
  style: PropTypes.any,
  buttonStyle: PropTypes.any,
  mobileStyle: PropTypes.any,
};

export default Button;
