import { ProgrammeTypes } from "../types/constants";
import { trackViewContent } from "./facebook-pixel-helper";
import { SCREENS } from "./screen-names-helper";

declare global {
  interface Window {
    analytics?: any; // eslint-disable-line
  }
}

export const trackPageView = (title: string): void => {
  if (window && window.analytics) {
    window.analytics.page(title, { screen: title });
    trackViewContent(title);
  }
};

export const trackButtonClick = (
  screen: string,
  answer: string | string[]
): void => {
  if (window && window.analytics) {
    window.analytics.track("Button clicked", {
      "Screen name": screen,
      Answer: answer,
    });
  }
};

export const identifyUser = (email: string): void => {
  if (window && window.analytics) {
    window.analytics.identify({
      email: email,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIndexOf = (programmeId: ProgrammeTypes, data: any[]) => {
  const index =
    data
      .map(function (e) {
        return e.type;
      })
      .indexOf(programmeId) + 1;
  return data.length - index + 1;
};

const formatProgrammeSelectionData = (
  sortedProgrammes: unknown[],
  selectedProgramme: ProgrammeTypes
): unknown => {
  return {
    "Screen name": SCREENS.RecommendationScreen,
    "Programme Selected": selectedProgramme,
    "Programme Selected Position": getIndexOf(
      selectedProgramme,
      sortedProgrammes
    ),
    "TDR Position": getIndexOf(ProgrammeTypes.TDR, sortedProgrammes),
    "Replacement 800 Position": getIndexOf(
      ProgrammeTypes.TDR_PLUS,
      sortedProgrammes
    ),
    "Low Carb Position": getIndexOf(ProgrammeTypes.LOW_CARB, sortedProgrammes),
    "Keto Position": getIndexOf(ProgrammeTypes.KETO, sortedProgrammes),
    "Wholefood 800 Position": getIndexOf(
      ProgrammeTypes.WHOLEFOODS,
      sortedProgrammes
    ),
  };
};

const formatProgrammeRenderedData = (sortedProgrammes: unknown[]): unknown => {
  return {
    "Screen name": SCREENS.RecommendationScreen,
    "TDR Position": getIndexOf(ProgrammeTypes.TDR, sortedProgrammes),
    "Replacement 800 Position": getIndexOf(
      ProgrammeTypes.TDR_PLUS,
      sortedProgrammes
    ),
    "Low Carb Position": getIndexOf(ProgrammeTypes.LOW_CARB, sortedProgrammes),
    "Keto Position": getIndexOf(ProgrammeTypes.KETO, sortedProgrammes),
    "Wholefood 800 Position": getIndexOf(
      ProgrammeTypes.WHOLEFOODS,
      sortedProgrammes
    ),
  };
};

export const trackProgrammeSelection = (
  sortedProgrammes: unknown[],
  selectedProgramme: ProgrammeTypes
): void => {
  const data = formatProgrammeSelectionData(
    sortedProgrammes,
    selectedProgramme
  );
  if (window && window.analytics) {
    window.analytics.track("Data submitted", data);
  }
};

export const trackProgrammeRender = (sortedProgrammes: unknown[]): void => {
  const data = formatProgrammeRenderedData(sortedProgrammes);
  if (window && window.analytics) {
    window.analytics.track("Programmes shown", data);
  }
};

export const trackProgrammeViewed = (
  sortedProgrammes: unknown[],
  viewedProgramme: ProgrammeTypes
): void => {
  const data = {
    Programme: viewedProgramme,
    Position: getIndexOf(viewedProgramme, sortedProgrammes),
  };
  if (window && window.analytics) {
    window.analytics.track("Programme viewed", data);
  }
};

export const trackSubmissionEvent = (data: unknown): void => {
  if (window && window.analytics) {
    window.analytics.track("Email submitted", {
      data: data,
    });
  }
};

export const trackErrorEvent = (
  name: string,
  email: string,
  error: string
): void => {
  if (window && window.analytics) {
    window.analytics.track(name, {
      email: email,
      error: error,
    });
  }
};

export const EVENT_NAMES = {
  Hubspot_Error: "Hubspot Error",
};
