import React from "react";
import styled, { css } from "styled-components";
import settings from "../../styles/global/settings";
import PropTypes from "prop-types";

const HabProgressBarContainer = styled.div.attrs(() => ({
  className: "ProgressBarContainer",
}))`
  height: 9px;
  width: 100%;
  display: flex;
  z-index: 10;
`;

const HabProgressBar = styled.div.attrs(() => ({
  className: "ProgressBar",
}))`
  height: 100%;
  width: 33.333%;
  display: flex;
  flex-direction: row;
  margin-right: 1px;
  margin-left: 1px;

  :nth-child(1) {
    margin-left: 0px;
  }

  :nth-child(3) {
    margin-right: 0px;
  }
`;

interface ChunkProps {
  width?: string;
  color?: string;
}

const HabProgressBarChunk = styled.div.attrs(() => ({
  className: "HabProgressBarChunk",
}))`
  height: 100%;
  ${(props: ChunkProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props: ChunkProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;

const ProgressBar = ({
  currentGroup,
  currentIndex,
  group1Count,
  group2Count,
  group3Count,
}: PropTypes.InferProps<typeof ProgressBar.propTypes>): JSX.Element => {
  const chunksOfOne = currentGroup === 0 ? currentIndex + 1 : group1Count;
  const chunksOfTwo =
    currentGroup === 1
      ? currentIndex + 1
      : currentGroup === 0
      ? 0
      : group2Count;
  const chunksOfThree =
    currentGroup === 2
      ? currentIndex + 1
      : currentGroup === 0 || currentGroup === 1
      ? 0
      : group3Count;
  const widthOfChunksOne = 100 / group1Count;
  const widthOfChunksTwo = 100 / group2Count;
  const widthOfChunksThree = 100 / group3Count;
  return (
    <HabProgressBarContainer>
      <HabProgressBar>
        {Array.from(Array(chunksOfOne)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg0-c-${index}`}
              width={`${widthOfChunksOne}%`}
              color={settings.color.primary_4}
            />
          );
        })}
        {Array.from(Array(group1Count - chunksOfOne)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg0-b-${index}`}
              width={`${widthOfChunksOne}%`}
              color={settings.color.path}
            />
          );
        })}
      </HabProgressBar>
      <HabProgressBar>
        {Array.from(Array(chunksOfTwo)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg1-c-${index}`}
              width={`${widthOfChunksTwo}%`}
              color={settings.color.primary_3}
            />
          );
        })}
        {Array.from(Array(group2Count - chunksOfTwo)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg1-b-${index}`}
              width={`${widthOfChunksTwo}%`}
              color={settings.color.path}
            />
          );
        })}
      </HabProgressBar>
      <HabProgressBar>
        {Array.from(Array(chunksOfThree)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg2-c-${index}`}
              width={`${widthOfChunksThree}%`}
              color={settings.color.primary_2}
            />
          );
        })}
        {Array.from(Array(group3Count - chunksOfThree)).map((chunk, index) => {
          return (
            <HabProgressBarChunk
              key={`pg2-b-${index}`}
              width={`${widthOfChunksThree}%`}
              color={settings.color.path}
            />
          );
        })}
      </HabProgressBar>
    </HabProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  currentGroup: PropTypes.number,
  currentIndex: PropTypes.number,
  group1Count: PropTypes.number,
  group2Count: PropTypes.number,
  group3Count: PropTypes.number,
};

export default ProgressBar;
