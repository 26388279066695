import styled, { css } from "styled-components"

import settings from "./settings"

export const HabPage = styled.div`
  overflow: hidden;
`

export const HabLeadCaptureTitle = styled.div.attrs(() => ({
  className: "HabLeadCaptureTitle",
}))`
  margin: auto;
  margin-top: 48px;
  h3 {
    font-size: 24px;
    line-height: 120%;
    ${props =>
      props.titleColor &&
      css`
        color: ${props.titleColor};
      `};
  }
`

export const HabLeadCaptureSubtitle = styled.div`
  margin: auto;
  margin-top: 16px;
  h3 {
    font-family: ${settings.font.default};
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 8px;
  }
`

export const MTB6 = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
`

export const Ps = styled.div`
  font-family: ${settings.font.default};
  color: ${settings.color.primary_1};
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  opacity: 60%;
`

export const P = styled.div`
  font-family: ${settings.font.default};
  color: ${settings.color.primary_1};
  font-size: 18px;
  line-height: 130%;
  font-weight: 400;
`

export const H3 = styled.div`
  font-family: ${settings.font.headings};
  color: ${settings.color.primary_1};
  font-size: 26px;
  line-height: 120%;
  font-weight: 400;
  letter-spacing: -1px;
  padding-top: 16px;
  padding-bottom: 18px;
`

export const H2 = styled.div`
  font-family: ${settings.font.headings};
  color: ${settings.color.primary_1};
  font-size: 32px;
  line-height: 110%;
  font-weight: 400;
  letter-spacing: -1px;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 16px;
`

export const HabLeadCaptureParagraph = styled.div.attrs(() => ({
  className: "HabLeadCaptureParagraph",
}))`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  padding-top: 24px;
  color: ${settings.color.primary_1};
  ${props =>
    props.italic &&
    css`
      font-style: italic;
    `};
  ${props =>
    props.left &&
    css`
      text-align: left;
    `};
  ${props =>
    props.smallPadding &&
    css`
      padding-top: 13px;
      padding-bottom: 13px;
    `};
  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `};
`

export const HabButtonPrimary = styled.div.attrs(() => ({
  className: "HabButtonPrimary",
}))`
  display: flex;
  text-align: center;
  a {
    min-width: 210px;
    font-size: 16px;
    color: #fff;
    background-color: ${settings.color.primary_1};
    border-radius: 25px;
    padding: 10px;
    border: 2px solid transparent;

    &:hover {
      background-color: #213530;
    }
  }

  ${props =>
    props.disabled &&
    css`
      a {
        border: 2px solid ${settings.color.grey};
        background-color: ${settings.color.grey};
        &:hover {
          background-color: ${settings.color.grey};
        }
      }
    `};
`

export const HabButtonSecondary = styled.div.attrs(() => ({
  className: "HabButtonSecondary",
}))`
  display: flex;
  text-align: center;
  a {
    min-width: 210px;
    font-size: 16px;
    color: ${settings.color.primary_1};
    border: 2px solid ${settings.color.primary_1};
    background-color: #fff;
    border-radius: 25px;
    padding: 10px;
    &:hover {
      border: 3px solid ${settings.color.primary_1};
    }
  }

  ${props =>
    props.disabled &&
    css`
      a {
        color: ${settings.color.grey};
        border: 2px solid ${settings.color.grey};
        &:hover {
          border: 2px solid ${settings.color.grey};
        }
      }
    `};
`
